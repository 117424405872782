import React from 'react';
import styled from 'styled-components';

const UnorderedList = styled.ul`
  padding: 0;
`;

const OrderedList = styled.ol`
  padding: 0;
  counter-reset: counterValue;
`;

export const List = ({ children, ordered }) => {
  return ordered ? (
    <OrderedList>{children}</OrderedList>
  ) : (
    <UnorderedList>{children}</UnorderedList>
  );
};

export const ListItem = styled.li`
  ${UnorderedList} & {
    list-style: none;
    padding-left: 2rem;
    ::before {
      content: '•';
      position: absolute;
      margin-left: -2rem;
    }
  }

  ${OrderedList} & {
    list-style: none;
    padding-left: 2rem;
    ::before {
      counter-increment: counterValue;
      content: counter(counterValue) '.';
      position: absolute;
      margin-left: -2rem;
    }
  }
`;

export const Li = ListItem;
