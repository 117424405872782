import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
`;

const centeredCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageContent = styled.main`
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 2.2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  ${props => (props.centered ? centeredCss : undefined)};

  @media screen and (min-width: 48em) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  @media screen and (min-width: 64em) {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`;

export const Text = styled.div`
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
`;
