import React from 'react';
import Page from 'components/pages/Page';
import { PageContent, H1, P, Text, OpacityLink } from 'components/ui';

const HomePage = () => (
  <Page>
    <PageContent centered>
      <Text>
        <H1 style={{ fontSize: '2rem' }}>Sergey Solovev</H1>
        {/* <H1 style={{ fontSize: '2rem' }}>Sergey Solovev</H1>
        <P noHyphenation>
          Work at the intersection of finance and software engineering.
          Quant&nbsp;trader. Lead software engineer at{' '}
          <OpacityLink
            target="_blank"
            rel="noopener"
            href="https://www.metric.ai/"
          >
            Metric.ai
          </OpacityLink>
          . Master&nbsp;of&nbsp;Science in Applied Mathematics and Computer
          Science. Develop and evaluate trading systems using a proprietary
          framework. Develop software for algo trading. Created a crypto
          arbitrage trading terminal supporting over a dozen of exchanges.
          Created a personal finance app which reveals balance over time from
          the transactions. Built and launched an educational scheduling and
          resource planning software for one of the biggest{' '}
          <OpacityLink href="https://spbu.ru" rel="noopener" target="_blank">
            universities
          </OpacityLink>{' '}
          in Russia. In Bali since 2016.
        </P>
        <P noHyphenation>
          Contact{' '}
          <OpacityLink
            target="_blank"
            rel="noopener"
            href="mailto:me@sergeysolovev.com"
          >
            me@sergeysolovev.com
          </OpacityLink>
          &nbsp;•{' '}
          <OpacityLink
            target="_blank"
            rel="noopener"
            href="https://github.com/sergeysolovev"
          >
            Github
          </OpacityLink>
          &nbsp;•{' '}
          <OpacityLink
            target="_blank"
            rel="noopener"
            href="https://id.linkedin.com/in/ssolovev"
          >
            LinkedIn
          </OpacityLink>
          &nbsp;•{' '}
          <OpacityLink
            target="_blank"
            rel="noopener"
            href="https://www.instagram.com/sergees/"
          >
            IG
          </OpacityLink>
        </P> */}
      </Text>
    </PageContent>
  </Page>
);

export default HomePage;
