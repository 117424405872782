import styled from 'styled-components';
import { colors } from 'ui';

export const PrimaryButton = styled.button`
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  background-color: ${colors.bodyText};
  border-color: ${colors.bodyText};
  outline: none;
  line-height: 1;
  padding: 0.5rem 0.8rem 0.6rem 0.8rem;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 150ms, background-color 150ms;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.bodyText};
  }
`;
