import React from 'react';
import routeConfig from './routeConfig';
import ErrorBoundary from 'components/ErrorBoundary';
import { Switch, Route } from 'react-router-dom';

const App = () => (
  <ErrorBoundary>
    <Switch>
      {routeConfig.map((props, index) => (
        <Route key={index} {...props} />
      ))}
    </Switch>
  </ErrorBoundary>
);

export default App;
