import React from 'react';
import Page from 'components/pages/Page';
import routes from 'routes';
import { PageContent, P, PrimaryButton } from 'components/ui';

const ErrorPage = () => (
  <Page title="Error">
    <PageContent centered>
      <P>Something went wrong on this page.</P>
      <PrimaryButton
        style={{ marginTop: '1rem' }}
        onClick={() => (window.location.href = routes.home.path)}
      >
        Reload
      </PrimaryButton>
    </PageContent>
  </Page>
);

export default ErrorPage;
