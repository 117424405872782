import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'ui';
import { Link as RouterLink } from 'react-router-dom';

const regularLinkStyles = css`
  text-decoration: none;
  color: ${colors.link};
  white-space: ${props => (props.nowrap ? 'nowrap' : undefined)};
  transition: color 150ms ease;

  &:hover {
    color: ${colors.linkHovered};
  }
`;

const opacityLinkStyles = css`
  text-decoration: none;
  color: inherit;
  white-space: ${props => (props.nowrap ? 'nowrap' : undefined)};
  transition: opacity 150ms ease;
  opacity: 1;

  &:hover {
    opacity: 0.67;
  }
`;

const RegularAnchor = styled.a`
  ${regularLinkStyles};
`;

const RegularRouterLink = styled(RouterLink)`
  ${regularLinkStyles};
`;

const OpacityAnchor = styled.a`
  ${opacityLinkStyles};
`;

const OpacityRouterLink = styled(RouterLink)`
  ${opacityLinkStyles};
`;

export const Link = ({ to, ...rest }) => {
  if (to) {
    return <RegularRouterLink to={to} {...rest} />;
  } else {
    return <RegularAnchor {...rest} />;
  }
};

export const A = Link;

export const OpacityLink = ({ to, ...rest }) => {
  if (to) {
    return <OpacityRouterLink to={to} {...rest} />;
  } else {
    return <OpacityAnchor {...rest} />;
  }
};
