import React from 'react';
import styled from 'styled-components';
import { colors } from 'ui';

const FigureContainer = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 1.1rem 0;
`;

const Image = styled.img`
  max-width: 900px;
  width: 100%;
`;

export const FigCaption = styled.figcaption`
  margin-top: 0.7rem;
  font-size: 0.875rem;
  text-align: center;
  color: ${colors.bodyTextLight};
`;

export const Figure = ({ src, children }) => {
  return (
    <FigureContainer>
      <Image src={src} />
      {children}
    </FigureContainer>
  );
};

export default Figure;
