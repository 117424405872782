import Home from 'content';
import Page404 from 'content/404';
import routes from 'routes';
import { loadablePage } from 'components/pages/loadablePage';

const routeConfig = [
  {
    ...routes.home,
    component: Home
  },
  {
    ...routes.about,
    component: loadablePage('about')
  },
  {
    ...routes.page404,
    component: Page404
  },
  {
    ...routes.writing,
    component: loadablePage('writing/index')
  },
  {
    ...routes.writing.demo,
    component: loadablePage('writing/demo/index')
  },
  {
    ...routes.writing.howThisSiteWasMade,
    component: loadablePage('writing/how-this-site-was-made/index')
  },
  {
    component: Page404
  }
];

export default routeConfig;
