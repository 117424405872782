import React from 'react';
import ErrorPage from 'components/pages/ErrorPage';

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  componentDidCatch({ message, stack }) {
    this.setState({ hasError: true });
  }
  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
