import React from 'react';
import Hyphenated from 'react-hyphen';
import styled from 'styled-components';
import Subs from './Subs';
import { textStyles } from 'ui';

const ParagraphWrapper = styled.p`
  margin-block-start: 0;
`;

export const P = ({ children, language, noHyphenation }) => (
  <ParagraphWrapper>
    {noHyphenation ? (
      children
    ) : (
      <Hyphenated language={language}>{children}</Hyphenated>
    )}
  </ParagraphWrapper>
);

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const SmallCaps = styled.span`
  ${props => (props.all ? textStyles.allSmallCaps : textStyles.smallCaps)};
`;

export const Frac = styled.span`
  font-feature-settings: 'frac';
`;

export const Sups = styled.span`
  font-feature-settings: 'sups';
`;

export const Salt = styled.span`
  font-feature-settings: 'salt';
`;

export const TabularLining = styled.span`
  font-feature-settings: 'tnum', 'lnum';
`;

export { Subs };
