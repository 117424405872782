import React from 'react';
import toID from 'to-id';
import styled from 'styled-components';
import { textStyles, spaceProps } from 'ui';

const isString = object => typeof object === 'string';

const renderId = children => {
  if (isString(children)) return toID(children);
  if (Array.isArray(children)) return toID(children.filter(isString).join(' '));
};

const WrapperLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const AnchorContainer = styled.span`
  position: absolute;
  margin-left: -1rem;
  width: 1rem;
  opacity: 0;
  transition: opacity 150ms ease-in;

  @media screen and (min-width: 64em) and (hover: hover) {
    ${WrapperLink}:hover & {
      opacity: 1;
    }
  }
`;

export const H1 = styled.h1`
  font-size: 1.35rem;
  color: hsla(0, 0%, 24%, 1);
  ${textStyles.display};
  ${spaceProps};
`;

const StyledH2 = styled.h2`
  font-size: 1.05rem;
  margin-top: 1.5em;
  margin-bottom: 0.4em;
  color: hsla(0, 0%, 22%, 1);
  ${textStyles.display};
  ${spaceProps};
`;

export const H2 = ({ children, anchor = true }) => {
  if (anchor) {
    const id = renderId(children);
    return (
      <StyledH2 id={id}>
        <WrapperLink href={`#${id}`}>
          {id ? (
            <AnchorContainer>
              <span>#</span>
            </AnchorContainer>
          ) : null}
          {children}
        </WrapperLink>
      </StyledH2>
    );
  }
  return <StyledH2>{children}</StyledH2>;
};
