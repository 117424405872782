import React from 'react';
import styled from 'styled-components';
import { colors, textStyles } from 'ui';

const Wrapper = styled.pre`
  display: block;
  background-color: ${colors.codeBackground};
  padding: 0.7rem 1rem;
  line-height: 1.2;
  overflow-x: auto;
  border-radius: 2px;
`;

const Content = styled.code`
  ${textStyles.code};
`;

export const CodeBlock = ({ children }) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
);

export const Code = styled.code`
  font-family: 'Atlas Typewriter Web';
  font-feature-settings: 'zero' 1;
`;
