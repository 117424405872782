import { createGlobalStyle } from 'styled-components';
import { textStyles, fontFaces } from 'ui';

const GlobalStyle = createGlobalStyle`
  ${fontFaces};

  * {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }

  body {
    ${textStyles.body};
    text-rendering: optimizeLegibility;
    margin: 0;
  }
`;

export default GlobalStyle;
