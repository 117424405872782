import React, { Fragment } from 'react';
import Head from 'components/Head';
import GlobalStyle from 'components/GlobalStyle';
import { PageContainer } from 'components/ui';

const Page = ({ title, description, children }) => (
  <Fragment>
    <GlobalStyle />
    <Head title={title} description={description} />
    <PageContainer>{children}</PageContainer>
  </Fragment>
);

export default Page;
