import React from 'react';
import routes from 'routes';
import appConfig from 'app-config';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

const Head = ({
  title,
  description,
  location: { pathname },
  match: { path }
}) => {
  const is404 = path === '/' && pathname !== '/';
  const url = is404 ? routes.page404.path : path;
  return (
    <Helmet titleTemplate="Sergey Solovev · %s" defaultTitle="Sergey Solovev">
      {description ? <meta name="description" content={description} /> : null}
      {title ? <title>{title}</title> : null}
      {url ? (
        <link rel="canonical" href={appConfig.websiteBaseAddress + url} />
      ) : null}
    </Helmet>
  );
};

export default withRouter(Head);
