import { css } from 'styled-components';

export const spaceProps = ({
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  padding,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  margin
}) => css`
  padding: ${padding};
  padding-top: ${paddingTop};
  padding-right: ${paddingRight};
  padding-bottom: ${paddingBottom};
  padding-left: ${paddingLeft};
  margin: ${margin};
  margin-top: ${marginTop};
  margin-right: ${marginRight};
  margin-bottom: ${marginBottom};
  margin-left: ${marginLeft};
`;
