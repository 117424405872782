import AtlasGroteskRegularWoff from 'fonts/AtlasGrotesk-Regular-Cy-Web.woff';
import AtlasGroteskRegularWoff2 from 'fonts/AtlasGrotesk-Regular-Cy-Web.woff2';
import AtlasGroteskRegularItalicWoff from 'fonts/AtlasGrotesk-RegularItalic-Cy-Web.woff';
import AtlasGroteskRegularItalicWoff2 from 'fonts/AtlasGrotesk-RegularItalic-Cy-Web.woff2';
import AtlasGroteskBoldWoff from 'fonts/AtlasGrotesk-Bold-Cy-Web.woff';
import AtlasGroteskBoldWoff2 from 'fonts/AtlasGrotesk-Bold-Cy-Web.woff2';
import AtlasGroteskBoldItalicWoff from 'fonts/AtlasGrotesk-BoldItalic-Cy-Web.woff';
import AtlasGroteskBoldItalicWoff2 from 'fonts/AtlasGrotesk-BoldItalic-Cy-Web.woff2';
import AtlasTypewriterRegularWoff from 'fonts/AtlasTypewriter-Regular-Web.woff';
import AtlasTypewriterRegularWoff2 from 'fonts/AtlasTypewriter-Regular-Web.woff2';
import { css } from 'styled-components';

export const fontFaces = css`
  @font-face {
    font-family: 'Atlas Grotesk LC Web';
    src: url(${AtlasGroteskRegularWoff}) format('woff2'),
      url(${AtlasGroteskRegularWoff2}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Atlas Grotesk LC Web';
    src: url(${AtlasGroteskRegularItalicWoff}) format('woff2'),
      url(${AtlasGroteskRegularItalicWoff2}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Atlas Grotesk LC Web';
    src: url(${AtlasGroteskBoldWoff}) format('woff2'),
      url(${AtlasGroteskBoldWoff2}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Atlas Grotesk LC Web';
    src: url(${AtlasGroteskBoldItalicWoff}) format('woff2'),
      url(${AtlasGroteskBoldItalicWoff2}) format('woff');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Atlas Typewriter Web';
    src: url(${AtlasTypewriterRegularWoff2}) format('woff2'),
      url(${AtlasTypewriterRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }
`;
