import React from 'react';
import Page from 'components/pages/Page';
import { PageContent, P, H1 } from 'components/ui';

const NotFoundPage = () => (
  <Page title="Not Found">
    <PageContent centered>
      <H1>Not Found</H1>
      <P>This page doesn’t exist or has been removed.</P>
    </PageContent>
  </Page>
);

export default NotFoundPage;
