import { route } from './route';

const routes = {
  home: route('/'),
  about: route('/about/'),
  page404: route('/404/', {
    ssrFileName: '404.html',
    ssrDir: '/'
  }),
  writing: route('/writing/', {
    demo: route('/writing/demo/'),
    howThisSiteWasMade: route('/writing/how-this-site-was-made/')
  })
};

export default routes;
