export const fontMetrics = {
  atlas: {
    xHeight: 527,
    capHeight: 765
  },
  lexicon: {
    xHeight: 491,
    capHeight: 678
  },
  corporate: {
    xHeight: 475,
    capHeight: 668
  }
};
