import { css } from 'styled-components';
import { colors } from 'ui';

const body = css`
  font-family: 'Atlas Grotesk LC Web', sans-serif;
  line-height: 1.45;
  color: ${colors.bodyText};
`;

const display = css`
  line-height: 1.3;
  font-family: 'Atlas Grotesk LC Web', sans-serif;
`;

const code = css`
  font-family: 'Atlas Typewriter Web';
  font-feature-settings: 'zero' 1;
  font-size: 0.75rem;
`;

const smallCaps = css`
  font-feature-settings: 'smcp';
`;

const allSmallCaps = css`
  font-feature-settings: 'smcp', 'c2sc';
`;

export const textStyles = {
  smallCaps,
  allSmallCaps,
  body,
  display,
  code
};
