import { A, Link, OpacityLink } from './links';
import { Code, CodeBlock } from './code';
import { PrimaryButton } from './buttons';
import { H1, H2 } from './headings';
import { PageContainer, PageContent, Text } from './layout';
import { Figure, FigCaption } from './pictures';
import { List, ListItem, Li } from './lists';
import {
  P,
  NoWrap,
  SmallCaps,
  Frac,
  Sups,
  Subs,
  Salt,
  TabularLining
} from './text';

export {
  P,
  NoWrap,
  SmallCaps,
  Frac,
  Subs,
  Sups,
  Salt,
  TabularLining,
  H1,
  H2,
  A,
  Link,
  OpacityLink,
  Code,
  CodeBlock,
  PrimaryButton,
  PageContainer,
  PageContent,
  Text,
  Figure,
  FigCaption,
  List,
  ListItem,
  Li
};
