export const route = (
  path,
  { exact = true, ssrDir = path, ssrFileName = 'index.html', ...rest } = {}
) => {
  return {
    path,
    exact,
    ssrDir,
    ssrFileName,
    ...rest
  };
};
