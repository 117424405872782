const characterMap = {
  '0': '\u2080',
  '1': '\u2081',
  '2': '\u2082',
  '3': '\u2083',
  '4': '\u2084',
  '5': '\u2085',
  '6': '\u2086',
  '7': '\u2087',
  '8': '\u2088',
  '9': '\u2089',
  '+': '\u208A',
  '-': '\u208B',
  '=': '\u208C',
  '(': '\u208D',
  ')': '\u208E'
};

const Subs = ({ children }) => {
  if (typeof children === 'string') {
    return children
      .split('')
      .map(char => characterMap[char])
      .filter(Boolean);
  }
  return children;
};

export default Subs;
