const twoThirds = 2 / 3;
const base = 1 - (1 - twoThirds) / 2;
const lumScale = [...Array(12).keys()]
  .map(index => ((1 - Math.pow(base, index)) * 100).toFixed(0))
  .map(Number);
const grays = lumScale.map(lum => `hsla(0, 0%, ${lum}%, 1)`);

export const colors = {
  grays,
  bodyText: grays[1],
  bodyTextLight: grays[3],
  link: 'hsla(210, 95%, 30%, 1)',
  linkHovered: 'hsla(210, 95%, 30%, 0.66)',
  black: 'hsla(0, 0%, 0%, 1)',
  codeBackground: 'hsla(0, 0%, 97%, 1)'
};
